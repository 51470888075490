<template>
  <div>
    <div class="container">
      <van-cell-group inset class="dataIn" v-if="forbit">
        <van-cell title="设备名称" :value="deviceName"/>
        <van-cell title="审核部门" :value="auditDepartment" @click="subMint"/>
        <van-cell title="审核人" :value="auditPerson"/>
        <van-cell title="姓名" :value="vistorName"/>
        <van-cell title="联系电话" :value="vistorTel"/>
        <van-cell title="身份证号" :value="vistorIdNumber"/>
        <van-cell title="车牌号" :value="licenseNumber"/>
        <van-cell title="预约单位名称" :value="company"/>
        <van-cell title="预约负责人" :value="principalName"/>
        <van-cell title="联系方式" :value="principalTel"/>
        <van-cell title="来访事由" :value="visitPurpose"/>
      </van-cell-group>
      <van-cell-group inset class="dataIn" v-if="!forbit">
        <van-cell title="设备编号" :value="deviceNo"/>
        <van-field label="设备名称" input-align="right" v-model="deviceName"/>
        <van-field label="审核部门" input-align="right" v-model="auditDepartment"/>
        <van-field label="审核人" input-align="right" v-model="auditPerson"/>
        <van-field label="姓名" input-align="right" v-model="vistorName"/>
        <van-field label="联系电话" input-align="right" v-model="vistorTel"/>
        <van-field label="身份证号" input-align="right" v-model="vistorIdNumber"/>
        <van-field label="车牌号" input-align="right" v-model="licenseNumber"/>
        <van-field label="预约单位名称" input-align="right" v-model="company"/>
        <van-field label="预约负责人" input-align="right" v-model="principalName"/>
        <van-field label="联系方式" input-align="right" v-model="principalTel"/>
        <van-field label="来访事由" input-align="right" v-model="visitPurpose"/>
        <div class="btnInfo">
          <div @click="hideData" class="hideBtn">取消</div>
          <div @click="saveData" class="saveBtn">保存</div>
        </div>
      </van-cell-group>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      count: 0,
      forbit: true,
      deviceName: '',
      deviceNo: '',
      auditDepartment: '',
      auditPerson: '',
      vistorName: '',
      vistorTel: '',
      vistorIdNumber: '',
      licenseNumber: '',
      company: '',
      principalName: '',
      principalTel: '',
      visitPurpose: ''
    }
  },
  mounted () {
    this.deviceNo = this.$route.query.deviceNo
    this.getInfo(this.deviceNo)
    document.title = '人员信息'
  },

  methods: {
    //请求
    async getInfo (deviceNo) {
      const { data: res } = await this.$axios.get(
          `fanztech-workcard/workcard-info/detail?deviceNo=${deviceNo}`
      );
      (this.deptName = res.data.deptName),
          (this.auditDepartment = res.data.auditDepartment),
          (this.auditPerson = res.data.auditPerson),
          (this.vistorName = res.data.vistorName),
          (this.vistorTel = res.data.vistorTel),
          (this.vistorIdNumber = res.data.vistorIdNumber),
          (this.licenseNumber = res.data.licenseNumber),
          (this.company = res.data.company),
          (this.principalName = res.data.principalName),
          (this.principalTel = res.data.principalTel),
          (this.visitPurpose = res.data.visitPurpose),
          (this.deviceName = res.data.deviceName)
      if (this.deviceName === '') this.forbit = false
    },
    subMint () {
      this.count++
      if (this.count >= 6) {
        this.forbit = false
        this.count = 0
      }
    },

    hideData () {
      this.forbit = true
    },
    //提交保存
    async saveData () {
      this.forbit = true
      const params = {
        auditDepartment: this.auditDepartment,
        auditPerson: this.auditPerson,
        vistorName: this.vistorName,
        vistorTel: this.vistorTel,
        vistorIdNumber: this.vistorIdNumber,
        licenseNumber: this.licenseNumber,
        company: this.company,
        principalName: this.principalName,
        principalTel: this.principalTel,
        visitPurpose: this.visitPurpose,
        deviceName: this.deviceName,
        deviceNo: this.deviceNo
      }
      const res = await this.$axios.post(
          'fanztech-workcard/workcard-info/save',
          params
      )
      if (res.data.code === '200') {
        // return uni.showToast({
        //   title: '提交成功',
        //   duration: 2000,
        //   icon: 'none',
        // })
      }
    },
  },
}
</script>

<style>
.van-cell {
  margin-top: 20px;

}

.btnInfo {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
}

.hideBtn {
  background-color: #AFB6B5;
  height: 50px;
  width: 100px;
  text-align: center;
  line-height: 50px;
}

.saveBtn {
  background-color: #34A996;
  text-align: center;
  line-height: 50px;
  height: 50px;
  width: 100px;
}
</style>
