<template>
  <div id="app">
      <router-view></router-view>
  </div>
</template>

<script>
 
import workcard from './components/workcard.vue';

export default {
  name: 'App',
  components: {
    workcard
  }
}
</script>

<style>

  
</style>
