import Vue from "vue";
import VueRouter from 'vue-router'
import workcard from '../components/workcard'


Vue.use(VueRouter)


const routes = [
    {
        path:'/workcard/info',
        component:workcard
    }
]

const router = new VueRouter({
    routes
})

export default router